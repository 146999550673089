import React, { useState, SyntheticEvent } from 'react'
import { useAuth } from '@contexts/AuthProvider'
import { Heading, Button, Flex, Container as ChakraContainer, Stack } from '@chakra-ui/react'
import NextLink from 'next/link'
import { Container } from '@components/Login'

const Login = () => {
  const { login, isLoading, isAuthenticated, error } = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [show, setShow] = React.useState(false)

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()
    login({ email, password })
  }

  if (isAuthenticated) {
    return (
      <Flex w="100vw" h="100vh" backgroundColor="quredGray.600">
        <ChakraContainer display="flex" alignItems="center" justifyContent="center">
          <Stack spacing={4}>
            <Heading size="xl" as="h1">
              You are already logged in
            </Heading>
            <NextLink href="/appointment" passHref>
              <Button colorScheme="quredBlue">Go Home</Button>
            </NextLink>
          </Stack>
        </ChakraContainer>
      </Flex>
    )
  }

  return (
    <Container
      show={show}
      setShow={setShow}
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      handleSubmit={handleSubmit}
      error={error}
      loading={isLoading}
    />
  )
}

export default Login
