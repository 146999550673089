import React, { FC, Dispatch, SetStateAction, SyntheticEvent } from 'react'
import {
  Container,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Flex,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Spacer,
  FormHelperText
} from '@chakra-ui/react'
import Image from 'next/image'
import styles from './Container.module.css'

interface Props {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  email: string
  setEmail: Dispatch<SetStateAction<string>>
  password: string
  setPassword: Dispatch<SetStateAction<string>>
  handleSubmit: (e: SyntheticEvent) => void
  error: string
  loading: boolean
}

const LoginContainer: FC<Props> = ({
  setShow,
  show,
  email,
  setEmail,
  password,
  setPassword,
  handleSubmit,
  error,
  loading
}) => {
  return (
    <Flex
      width="100vw"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      backgroundColor="quredGray.600"
      flexDirection="column"
    >
      <Container display="flex" flexDirection="column" alignItems="center" mb={8}>
        <Image
          layout="fixed"
          src="/assets/q-logo-blue-circle.png"
          alt="qured-logo"
          width="60x"
          height="60px"
        />
        <Spacer mt={6} />
        <Heading as="h3" size="lg" textAlign="center">
          Log in to your account
        </Heading>
      </Container>
      <Box
        position="relative"
        display="flex"
        w={450}
        h={325}
        backgroundColor="whiteAlpha.900"
        p={8}
        borderRadius="xl"
        boxShadow="xl"
        alignItems="center"
      >
        <form className={styles.formWrapper} onSubmit={handleSubmit}>
          <FormControl isRequired>
            <FormLabel>Email address</FormLabel>
            <Input
              required
              value={email}
              pr="4.5rem"
              type="email"
              placeholder="Enter email"
              mb={6}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Password</FormLabel>
            <InputGroup size="md" mb={6}>
              <Input
                required
                pr="4.5rem"
                type={show ? 'text' : 'password'}
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={() => setShow(!show)}>
                  {show ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl>
            <Button
              type="submit"
              colorScheme="quredBlue"
              size="md"
              mt={2}
              width="100%"
              isLoading={loading}
            >
              Login
            </Button>
            <FormHelperText color="red.500" fontSize="xs">
              {error}
            </FormHelperText>
          </FormControl>
        </form>
      </Box>
    </Flex>
  )
}
export default LoginContainer
